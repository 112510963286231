<template>
    <div class="" >
        <ContentLoader width="300" height="120">
            <rect x="245" y="0" rx="3" ry="3" width="25" height="25" />
            <rect x="55" y="0" rx="3" ry="3" width="170" height="25" />

            <rect x="245" y="40" rx="3" ry="3" width="25" height="25" />
            <rect x="25" y="40" rx="3" ry="3" width="200" height="25" />

            <rect x="245" y="80" rx="3" ry="3" width="25" height="25" />
            <rect x="55" y="80" rx="3" ry="3" width="170" height="25" />
        </ContentLoader>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
    components: {
        ContentLoader,
    }
}
</script>

<style>

</style>