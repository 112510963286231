<template>
  <div class="px-4">
    <div class="title font-weight-bold h5 text-center my-4 mt-2">حسابي</div>
    
    <div v-if="meLoading" class="pt-3 mb-4">
      <ProfileInfoLoader />
    </div>

    <div class="row justify-content-md-center mx-0 px-3 pt-3 mb-4 text-md-center" v-if="me">
      <h5 class="mb-3"><i class="la la-phone me-3 la-lg text-brand"></i> {{ me.phone }}</h5>
      <h5 class="mb-3 d-flex d-md-block">
        <div class="d-flex flex-column justify-content-center d-md-inline-block">
          <i class="la la-user-check me-3 la-lg text-brand"></i> 
        </div>
        <small class="">مشترك منذ</small> {{ me.subscription_model.created_at }}
      </h5>
      <h5 class="mb-3"><i class="la la-mobile me-3 la-lg text-brand"></i> <small>حالة الإشتراك</small> 
        <span class="text-success ms-2" v-if="me.subscription_model.active_billing_until && new Date(me.subscription_model.active_billing_until) > (new Date())">
          نشط 
          <div class="card-secondary-lable text-sm mt-2 ps-5">حتى {{ me.subscription_model.active_billing_until }}</div>
        </span>
        <span class="text-danger ms-2" v-else>غير نشط</span>
      </h5>
    </div>

    <div class="text-center py-5" v-if="me">
      
      <button @click="logout" class="btn bg-brand text-light shadow-sm mb-3" :disabled="unsubscriptionLoading">تسجيل الخروج</button>
      <div></div>
      <button @click="unsubscribe" class="btn main-card text-danger shadow-sm" :disabled="unsubscriptionLoading"><i class="la la-spinner la-spin me-2" v-if="unsubscriptionLoading"></i> إلغاء اﻹشتراك</button>
    </div>

  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import ProfileInfoLoader from '../../components/Loader/ProfileInfoLoader.vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router'
  import { useToast } from 'vue-toastification';
  import { ref } from '@vue/reactivity';

  export default {
    components: { ProfileInfoLoader },

    mounted() {
      this.profile()
    },

    setup() {

      const store = useStore()
      const router = useRouter()
      const toast = useToast()

      const unsubscriptionLoading = ref(false)
      const meLoading = ref(false)

      const me = ref(null)

      const logout = () => {
        store.dispatch('LOGOUT_USER')
        router.push({ name: 'LoginInfo' })
        console.log("Logged out")
      }

      return {
        toast,
        logout,
        unsubscriptionLoading,
        meLoading,
        me,
      }
    },

    methods: {
      unsubscribe() {
        if(confirm('هل تريد إلغاء اﻹشتراك حقا؟')) {
          this.unsubscribeUser()
        }
      },

      profile() {
        this.meLoading = true
        this.$apollo.query({
          query: gql`
            query {
              me {
                id
                phone

                subscription_model {
                  active_billing_until
                  created_at
                }
              }
            }
          `,
        })
        .then((response) => {
          this.meLoading = false
          this.me = response.data.me
        })
        .catch((error) => {
          this.meLoading = false
          this.reportError(error)
        })
      },

      unsubscribeUser() {
        this.unsubscriptionLoading = true
        this.$apollo
          .mutate({
            mutation: gql`
              mutation {
                unsubscribeUser {
                  message
                  code
                  success
                  payload
                }
              }
            `,
            // Parameters
            variables: {
              //
            },
          })
          .then((data) => {
            // Result
            console.log(data.data.unsubscribeUser)
            if(data.data.unsubscribeUser.success) {
              this.toast.success(data.data.unsubscribeUser.message)
              this.logout();
            }else {
              this.toast.error(data.data.unsubscribeUser.message)
            }
            this.unsubscriptionLoading = false;
          })
          .catch((error) => {
            console.log(error)
            this.unsubscriptionLoading = false
            this.reportError()
          });
      },

      reportError(error) {
        let { graphQLErrors } = error;

        if (graphQLErrors && graphQLErrors[0].extensions.category === "authentication") {
          this.toast.warning('إنتهت صلاحية الجلسة، الرجاء إعادة تسجيل الدخول')

          this.logout()
        }
      }
    },
  }
</script>

<style>

</style>